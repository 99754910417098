<template>
  <div>
    <div class="loadingBox" v-if="loadingNum == 1">
      <div class="loadingStyle">
        <div class="big-loading">
          <div class="pl__dot" v-for="item in 12" :key="item"></div>
          <div class="pl__text">请稍等...</div>
        </div>
      </div>
    </div>
    <div class="module-loading" v-else>
      <div class="loader-inner ball-clip-rotate">
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "loading-module",
  props: {
    loadingNum: [String, Number],
  },
};
</script>

<style scoped lang="scss">
@import "@/utils/css/global.scss";

.loadingBox {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  background-color: #454954;
  color: #e3e4e8;
  background-image: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.2)
  );
  display: grid;
  place-items: center;
  transition: background-color 0.3s, color 0.3s;

  .loadingStyle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .big-loading {
      box-shadow: 2em 0 2em rgba(0, 0, 0, 0.2) inset,
        -2em 0 2em rgba(255, 255, 255, 0.1) inset;
      @include flexLayout-Mixin;
      position: relative;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      transform: rotateX(30deg) rotateZ(45deg);
      width: 15em;
      height: 15em;
      border-radius: 50%;

      .pl__dot {
        animation-name: shadow;
        box-shadow: 0.1em 0.1em 0 0.1em black, 0.3em 0 0.3em rgba(0, 0, 0, 0.5);
        top: calc(50% - 0.75em);
        left: calc(50% - 0.75em);
        width: 1.5em;
        height: 1.5em;
        border-radius: 50%;
      }

      .pl__dot,
      .pl__dot:before,
      .pl__dot:after {
        animation-duration: 2s;
        animation-iteration-count: infinite;
        position: absolute;
      }

      .pl__dot:before,
      .pl__dot:after {
        content: "";
        display: block;
        left: 0;
        width: inherit;
        transition: background-color 0.3s;
      }

      .pl__dot:before {
        animation-name: pushInOut1;
        background-color: #454954;
        border-radius: inherit;
        box-shadow: 0.05em 0 0.1em rgba(255, 255, 255, 0.2) inset;
        height: inherit;
        z-index: 1;
      }

      .pl__dot:after {
        animation-name: pushInOut2;
        background-color: #255ff4;
        border-radius: 0.75em;
        box-shadow: 0.1em 0.3em 0.2em rgba(255, 255, 255, 0.4) inset,
          0 -0.4em 0.2em #2e3138 inset, 0 -1em 0.25em rgba(0, 0, 0, 0.3) inset;
        bottom: 0;
        clip-path: polygon(0 75%, 100% 75%, 100% 100%, 0 100%);
        height: 3em;
        transform: rotate(-45deg);
        transform-origin: 50% 2.25em;
      }

      .pl__dot:nth-child(1) {
        transform: rotate(0deg) translateX(5em) rotate(0deg);
        z-index: 5;
      }

      .pl__dot:nth-child(1),
      .pl__dot:nth-child(1):before,
      .pl__dot:nth-child(1):after {
        animation-delay: 0s;
      }

      .pl__dot:nth-child(2) {
        transform: rotate(-30deg) translateX(5em) rotate(30deg);
        z-index: 4;
      }

      .pl__dot:nth-child(2),
      .pl__dot:nth-child(2):before,
      .pl__dot:nth-child(2):after {
        animation-delay: -0.1666666667s;
      }

      .pl__dot:nth-child(3) {
        transform: rotate(-60deg) translateX(5em) rotate(60deg);
        z-index: 3;
      }

      .pl__dot:nth-child(3),
      .pl__dot:nth-child(3):before,
      .pl__dot:nth-child(3):after {
        animation-delay: -0.3333333333s;
      }

      .pl__dot:nth-child(4) {
        transform: rotate(-90deg) translateX(5em) rotate(90deg);
        z-index: 2;
      }

      .pl__dot:nth-child(4),
      .pl__dot:nth-child(4):before,
      .pl__dot:nth-child(4):after {
        animation-delay: -0.5s;
      }

      .pl__dot:nth-child(5) {
        transform: rotate(-120deg) translateX(5em) rotate(120deg);
        z-index: 1;
      }

      .pl__dot:nth-child(5),
      .pl__dot:nth-child(5):before,
      .pl__dot:nth-child(5):after {
        animation-delay: -0.6666666667s;
      }

      .pl__dot:nth-child(6) {
        transform: rotate(-150deg) translateX(5em) rotate(150deg);
        z-index: 1;
      }

      .pl__dot:nth-child(6),
      .pl__dot:nth-child(6):before,
      .pl__dot:nth-child(6):after {
        animation-delay: -0.8333333333s;
      }

      .pl__dot:nth-child(7) {
        transform: rotate(-180deg) translateX(5em) rotate(180deg);
        z-index: 2;
      }

      .pl__dot:nth-child(7),
      .pl__dot:nth-child(7):before,
      .pl__dot:nth-child(7):after {
        animation-delay: -1s;
      }

      .pl__dot:nth-child(8) {
        transform: rotate(-210deg) translateX(5em) rotate(210deg);
        z-index: 3;
      }

      .pl__dot:nth-child(8),
      .pl__dot:nth-child(8):before,
      .pl__dot:nth-child(8):after {
        animation-delay: -1.1666666667s;
      }

      .pl__dot:nth-child(9) {
        transform: rotate(-240deg) translateX(5em) rotate(240deg);
        z-index: 4;
      }

      .pl__dot:nth-child(9),
      .pl__dot:nth-child(9):before,
      .pl__dot:nth-child(9):after {
        animation-delay: -1.3333333333s;
      }

      .pl__dot:nth-child(10) {
        transform: rotate(-270deg) translateX(5em) rotate(270deg);
        z-index: 5;
      }

      .pl__dot:nth-child(10),
      .pl__dot:nth-child(10):before,
      .pl__dot:nth-child(10):after {
        animation-delay: -1.5s;
      }

      .pl__dot:nth-child(11) {
        transform: rotate(-300deg) translateX(5em) rotate(300deg);
        z-index: 6;
      }

      .pl__dot:nth-child(11),
      .pl__dot:nth-child(11):before,
      .pl__dot:nth-child(11):after {
        animation-delay: -1.6666666667s;
      }

      .pl__dot:nth-child(12) {
        transform: rotate(-330deg) translateX(5em) rotate(330deg);
        z-index: 6;
      }

      .pl__dot:nth-child(12),
      .pl__dot:nth-child(12):before,
      .pl__dot:nth-child(12):after {
        animation-delay: -1.8333333333s;
      }

      .pl__text {
        font-size: 0.2813rem;
        max-width: 5rem;
        position: relative;
        text-shadow: 0 0 0.1em rgba(227, 228, 232, 0.5);
        transform: rotateZ(-45deg);
      }
      /* Animations */
      @keyframes shadow {
        from {
          animation-timing-function: ease-in;
          box-shadow: 0.1em 0.1em 0 0.1em black,
            0.3em 0 0.3em rgba(0, 0, 0, 0.3);
        }
        25% {
          animation-timing-function: ease-out;
          box-shadow: 0.1em 0.1em 0 0.1em black,
            0.8em 0 0.8em rgba(0, 0, 0, 0.5);
        }
        50%,
        to {
          box-shadow: 0.1em 0.1em 0 0.1em black,
            0.3em 0 0.3em rgba(0, 0, 0, 0.3);
        }
      }

      @keyframes pushInOut1 {
        from {
          animation-timing-function: ease-in;
          background-color: #454954;
          transform: translate(0, 0);
        }
        25% {
          animation-timing-function: ease-out;
          background-color: #5583f6;
          transform: translate(-71%, -71%);
        }
        50%,
        to {
          background-color: #454954;
          transform: translate(0, 0);
        }
      }

      @keyframes pushInOut2 {
        from {
          animation-timing-function: ease-in;
          background-color: #454954;
          clip-path: polygon(0 75%, 100% 75%, 100% 100%, 0 100%);
        }
        25% {
          animation-timing-function: ease-out;
          background-color: #255ff4;
          clip-path: polygon(0 25%, 100% 25%, 100% 100%, 0 100%);
        }
        50%,
        to {
          background-color: #454954;
          clip-path: polygon(0 75%, 100% 75%, 100% 100%, 0 100%);
        }
      }
    }
  }
}
.module-loading {
  position: relative;
  .ball-clip-rotate > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: 0.7813rem;
    height: 0.7813rem;
    border-radius: 100%;
    margin: 0.0313rem;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    border: 2px solid #fff;
    border-bottom-color: transparent;
    width: 0.7813rem;
    height: 0.7813rem;
    background: transparent !important;
    display: inline-block;
    -webkit-animation: rotate 0.75s 0s linear infinite;
    animation: rotate 0.75s 0s linear infinite;
  }

  @keyframes rotate {
    0% {
      -webkit-transform: rotate(0deg) scale(1);
      transform: rotate(0deg) scale(1);
    }

    50% {
      -webkit-transform: rotate(180deg) scale(0.6);
      transform: rotate(180deg) scale(0.6);
    }

    100% {
      -webkit-transform: rotate(360deg) scale(1);
      transform: rotate(360deg) scale(1);
    }
  }
}
</style>
