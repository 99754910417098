<template>
  <div class="paginationBox">
    <!-- 总数据 -->
    <div class="total">
      共<i> {{ total }} </i>条数据
    </div>

    <!-- 上一页 -->
    <button
      class="left"
      :disabled="prevClickDisable"
      :class="prevClickDisable ? 'disabledStyle' : ''"
      @click="prevClick"
    >
      ←
    </button>

    <!-- 页码 -->
    <div class="center">
      <!-- 页码 -->
      <div class="pages">
        第<i>{{ pages }} </i>页
      </div>
    </div>

    <!-- 下一页 -->
    <button
      class="right"
      :disabled="nextClickDisable"
      :class="nextClickDisable ? 'disabledStyle' : ''"
      @click="nextClick"
    >
      →
    </button>
  </div>
</template>

<script>
export default {
  name: "pagination-module",

  /* 公共分页组件 */

  /**
   * 使用须知:
   * 1.必须需要传递 total总数 totalPages总页数  pages页数 三个数据
   * 2.computed 计算属性通过判断pages来禁用解除按钮的的状态
   * 3.使用了部分scss公共css样式
   * 4.main.js注册全局公共组件
   *
   * 2023-12-1日封装
   */

  data() {
    return {
      nextNum: 1, // 下一页
      prevNum: 1, // 上一页
    };
  },

  props: {
    total: {
      typeof: Number, // 接收的数据总条数
      required: true, // 参数必须传递
    },

    totalPages: {
      typeof: Number, // 接收的数据总页数
      required: true, // 参数必须传递
    },

    pages: {
      typeof: Number, // 页数
      required: true, // 参数必须传递
    },
  },

  methods: {
    /* 上一页 */
    prevClick() {
      this.prevNum = this.pages;
      this.prevNum--;
      this.$emit("prevClick", this.prevNum);
    },

    /* 下一页 */
    nextClick() {
      this.nextNum = this.pages;
      this.nextNum++;
      this.$emit("nextClick", this.nextNum);
    },
  },

  computed: {
    /***
     * 分页符是否禁用
     * button true为禁用状态
     * >1返回false,解除button禁用状态
     */

    /* 上一页 */
    prevClickDisable() {
      if (this.pages > 1) {
        return false;
      } else {
        return true;
      }
    },

    /* 下一页 */
    nextClickDisable() {
      if (this.pages == this.totalPages) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/utils/css/global.scss";

.paginationBox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: vh(30);
  color: #ffff;

  // 总数
  .total {
    font-size: 0.1563rem;
    font-weight: 600;
    letter-spacing: 0.0156rem;
    margin-right: 0.1875rem;

    i {
      font-style: normal;
      margin: 0 0.0469rem;
      @include cursorPointer-Mixin;
    }
  }

  //页码
  .center {
    display: flex;
    align-items: center;
    margin: 0 0.2344rem;

    .pages {
      font-size: 0.1563rem;
      font-weight: 600;
      margin: 0 0.0938rem;
      @include cursorPointer-Mixin;

      i {
        font-style: normal;
        margin: 0 0.0781rem;
      }
    }

    .pagesColor {
      color: #409eff;
    }
  }

  // 按钮样式
  .left,
  .right {
    width: 0.4688rem;
    height: 0.4688rem;
    font-size: 0.1563rem;
    color: black;
    background: #f4f4f5;
    border: 0;
    border-radius: 0.0781rem;
    @include cursorPointer-Mixin;

    &:active {
      @include mouseup-Mixin;
    }
  }

  // button的禁用状态样式
  .disabledStyle {
    cursor: not-allowed;
  }
}
</style>
