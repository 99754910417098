<template>
  <div class="realtimeDeviceForm">
    <!-- 标题 -->
    <div class="realtimeDeviceForm_header">
      <div class="realtimeDeviceForm_header_title">设备地址</div>
      <div class="realtimeDeviceForm_header_title">设备名称</div>
      <div class="realtimeDeviceForm_header_title">设备类型</div>
      <div class="realtimeDeviceForm_header_title">设备状态</div>
      <div class="realtimeDeviceForm_header_title">经度</div>
      <div class="realtimeDeviceForm_header_title">纬度</div>
      <div class="realtimeDeviceForm_header_title">详细信息</div>
    </div>
    <!-- 查询结果 -->
    <div class="sarch_Results">
      <div
        class="table-deviceDetails-content realtimeDeviceForm_content Go"
        v-for="(item, i) in search_List"
        :key="i"
      >
        <div class="realtimeDeviceForm_content_title">
          {{ item.deviceAddr }}
        </div>
        <div class="realtimeDeviceForm_content_title">
          {{ item.deviceName }}
        </div>
        <div class="realtimeDeviceForm_content_title">
          {{ brandDeviceTypes(item.deviceType) }}
        </div>
        <div class="realtimeDeviceForm_content_title">
          {{ deviceStatus(item.status) }}
        </div>
        <div class="realtimeDeviceForm_content_title">{{ item.lng }}</div>
        <div class="realtimeDeviceForm_content_title">{{ item.lat }}</div>
        <div class="realtimeDeviceForm_content_title">
          <button
            class="realtimeDeviceForm_Btn"
            :data-id="item"
            @click="queryDetails(item)"
          >
            详情
          </button>
        </div>
      </div>
    </div>
    <!-- 内容 -->
    <vue-seamless-scroll
      :data="copyData"
      :class-option="optionHover"
      ref="vueSeamlessScroll"
      class="seamless-warp"
    >
      <div
        class="table-deviceDetails-content realtimeDeviceForm_content Go"
        v-for="(item, index) in dataList"
        :key="index"
      >
        <div class="realtimeDeviceForm_content_title">
          {{ item.deviceAddr }}
        </div>
        <div class="realtimeDeviceForm_content_title">
          {{ item.deviceName }}
        </div>
        <div class="realtimeDeviceForm_content_title">
          {{ brandDeviceTypes(item.deviceType) }}
        </div>
        <div
          class="realtimeDeviceForm_content_title"
          :class="item.status === 'offline' ? 'state_offline' : 'state_online'"
        >
          {{ deviceStatus(item.status) }}
        </div>
        <div class="realtimeDeviceForm_content_title">{{ item.lng }}</div>
        <div class="realtimeDeviceForm_content_title">{{ item.lat }}</div>
        <div class="realtimeDeviceForm_content_title">
          <button
            class="realtimeDeviceForm_Btn"
            :data-id="item"
            @click="queryDetails(item)"
          >
            详情
          </button>
        </div>
      </div>
    </vue-seamless-scroll>
  </div>
</template>

<script>
// 引入工具函数
import { openPopupAPI } from "@/utils/tool.js";

export default {
  name: "realtimeDeviceForm-module",
  props: {
    content_List: {
      typeof: Array, //表单数据
    },

    search_List: {
      typeof: Array, //表单数据
    },
  },

  data() {
    return {
      dataList: [], //数据循环
      optionHover: null, // vue-samless-scroll插件的实例方法
      copyData: [], // vu-samless-scroll插件需要复制的数据
    };
  },

  methods: {
    //品牌设备类型
    brandDeviceTypes(value) {
      const brandDeviceTypes = {
        worm: "虫情设备",
        spore: "孢子设备",
        met: "气象设备",
        soil: "墒情设备",
        irrigation: "智慧环控",
        camera: "摄像头",
        wormFlagship: "虫情设备",
      };

      return brandDeviceTypes[value] || "未知设备类型";
    },

    //设备状态
    deviceStatus(value) {
      const status = {
        online: "设备在线",
        offline: "设备离线",
        alarm: "设备报警",
      };

      return status[value] || "未知设备类型";
    },

    //查询详情
    queryDetails(value) {
      // 传递数据和打开哪个弹窗
      openPopupAPI(value, 1);
    },
  },

  watch: {
    content_List: {
      handler(newValue) {
        if (newValue.length === 0) return;
        this.dataList = newValue;
        // this.dataList = Array.from({ length: 30 }, () => newValue[0]);
        this.$nextTick(() => {
          // 调用 Mixin.js 中的方法
          this.scrollControl();
        });
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/utils/css/global.scss";

/* 表格滚动组件高度 */
.seamless-warp {
  height: vh(250);
  overflow: hidden;
}

.realtimeDeviceForm {
  position: relative;
  height: vh(300);
  color: #ffffff;

  .realtimeDeviceForm_header,
  .realtimeDeviceForm_content {
    width: 100%;
    height: vh(30);
    display: flex;

    .realtimeDeviceForm_header_title,
    .realtimeDeviceForm_content_title {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: vh(16);
      font-weight: 700;
    }

    .state_online {
      color: aqua;
    }

    .state_offline {
      color: red;
    }

    .realtimeDeviceForm_Btn {
      width: vw(70);
      height: vh(25);
      font-size: vh(12);
      border-radius: vh(5);
      cursor: pointer;
      border: 0;
      outline: 0;
    }
  }

  .sarch_Results {
    position: absolute;
    top: vh(30);
    left: 0;
    width: 100%;
    z-index: 99;

    .Go {
      background: #aaaaaa;
    }
  }

  .Go {
    margin-bottom: vh(10);
    height: vh(40);
    background: #112a45;

    .realtimeDeviceForm_content_title {
      font-size: vh(14);
    }
  }
}
</style>
