import Vue from "vue";
import App from "./App.vue";

// 引入路由
import router from "./router";
// 引入vuex
import store from "./store";

// 关闭生产环境下的提示信息
Vue.config.productionTip = false;

// 引入混入js文件
import mixin from "@/utils/mixin.js";
// 注册vue插件
Vue.mixin(mixin);

// 引入 utils工具文件下的global.scss文件清除css内置样式
import "./utils/css/global.scss";

// 引入 utils工具文件下的flexible.js文件进行页面的适配
import "./utils/flexible.js";

// 引入字体图标
import "/src/assets/fonts/iconfont.css";

// 引入表格滚动插件
import scroll from "vue-seamless-scroll";
// 注册vue插件
Vue.use(scroll);

// 引入时间格式化插件
import moment from "moment";
// 封装全局时间格式化过滤器
Vue.filter("timer", (val) => {
    return moment(val).format("YYYY-MM-DD HH:mm:ss");
});

// 引入全局路由守卫
import "./permission";

// 注册全局组件
import registrationComponent from "./utils/registrationComponent";
// 循环注册公共组件
Object.keys(registrationComponent).forEach((key) => {
    Vue.component(key, registrationComponent[key]);
});


new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");