const state = {
    data: null, // 传递数据
    remoteData: null, // 遥调信息组件的数据
    EchartsData: null, // 传递Echarts数据
};

const mutations = {
    // 获取数据
    getWeatherData(state, value) {
        state.data = value;
    },
    // 获取遥调信息组件的数据
    getRemoteData(state, value) {
        state.remoteData = value;
    },
};

const actions = {};

export default {
    namespaced: true, //添加命名空间,防止变量污染
    state,
    mutations,
    actions,
};