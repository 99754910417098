// http请求
import axios from "axios";
// 引入store
import store from "@/store";
// 引入env.js文件
import Projectmodel from "@/utils/env"


/***
 *
 * http请求
 *
 */

const http = axios.create({
    /**
     * 正式地址: http://api.farm.0531yun.cn
     * http://120.224.57.105:8001
     */
    baseURL: Projectmodel.renke_BaseURL, // 接口基地址
    timeout: 12000, // 设置接口请求的超时时间
});

//请求拦截器

http.interceptors.request.use(
    // 成功之后的回调
    (config) => {
        // 可以配置token参数
        config.headers.token = store.getters.token;

        return config;
    },
    // 失败之后的回调
    (error) => {
        return Promise.reject(error);
    }
);

//响应拦截器
http.interceptors.response.use(
    async(response) => {
        const codeList = [1003, 1004, 2000, 4000, 5000]; // 重新登录

        if (codeList.includes(response.data.code)) {
            await store.commit("token/clearToken");
        } else {
            return response.data;
        }
    },
    // 失败之后的回调
    async(error) => {
        await store.commit("token/clearToken");
        return Promise.reject(error);
    }
);

export default http;