<template>
  <div class="messageBox">
    <div class="message green" v-if="messageData.state">
      <div class="icon">
        <img src="../assets/imgs/success.svg" alt="" />
      </div>
      <div class="title">{{ messageData.data }}</div>
    </div>
    <div class="fail red" v-else>
      <div class="icon">
        <img src="../assets/imgs/fail.svg" alt="" />
      </div>
      <div class="title">{{ messageData.data }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "message-module",

  mounted() {
    const that = this;
    setTimeout(() => {
      that.$store.commit("message/closeMessage", false);
    }, 3000);
  },

  computed: {
    ...mapState("message", ["messageData"]),
  },
  watch: {
    messageData: {
      handler(value) {
        console.log(value);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/utils/css/global.scss";

.messageBox {
  position: fixed;
  top: 1.875rem;
  right: 0.4688rem;
  width: 4.6875rem;
  height: 0.5469rem;
  @include cursorPointer-Mixin;
  animation: slideLeft 1s forwards;

  @keyframes slideLeft {
    0% {
      right: -4.6875rem; /* 初始位置在最右边 */
    }

    100% {
      right: 0.4688rem; /* 最终位置保持在这里 */
    }
  }

  .fail,
  .message {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 0.1563rem;

    border-radius: 0.0781rem;

    .icon {
      @include flexLayout-Mixin;
      width: 0.1563rem;
      height: 0.1563rem;
      border-radius: 50%;
      margin-right: 0.1563rem;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .title {
      font-size: 0.1563rem;
    }
  }

  .red {
    background: #fef0f0;
    color: red;
  }
  .green {
    background: #f0f9eb;
    color: #67c23a;
  }
}
</style>
