const state = {
  popupState: false, // 弹窗状态
  optionId: null, // 用来判断打开哪一个弹窗组件
  RerenderingState: false, // 重新渲染数据
};

const mutations = {
  // 控制弹窗开启
  open(state, value) {
    state.popupState = value;
  },
  // 控制弹窗关闭
  close(state, value) {
    state.popupState = value;
  },
  // 选项
  option(state, value) {
    state.optionId = value;
  },
  // 重新渲染数据
  Rerendering(state, value) {
    state.RerenderingState = value;

    setTimeout(() => {
      state.RerenderingState = false;
    }, 500);
  },
};

const actions = {};

export default {
  namespaced: true, //添加命名空间,防止变量污染
  state,
  mutations,
  actions,
};
