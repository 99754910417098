<template>
  <div class="header">
    <div class="headerTitle" @click="platformFn">
      <div class="ChineseTitle">
        {{ Project().chineseTitle }}高标准农田数字化管理平台
      </div>
      <div class="EnglishTitle">
        {{ Project().englishTitle }}
      </div>
    </div>
    <div class="headerTool">
      <div class="toolModule">
        <div class="tool-module" @click="platformFn">管理平台</div>
      </div>
      <div class="toolModule">
        <div class="tool-module" @click="return_GoBack">返回</div>
      </div>
      <div class="toolTime">
        <div class="day">{{ time }}</div>
        <div class="year">{{ year }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { timeAPI } from "@/utils/tool";
// 引入env.js文件
import Projectmodel from "@/utils/env";

export default {
  name: "header-module",

  data() {
    return {
      timer: null,
      year: null,
      time: null,
    };
  },

  created() {
    this.year = timeAPI().specificDate;
    this.time = timeAPI().HourDate;
    this.timer = setInterval(() => {
      this.time = timeAPI().HourDate;
    }, 1000);
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },

  methods: {
    platformFn() {
      if (this.$route.path !== "/") {
        this.$router.push("/");
      }
    },
    return_GoBack() {
      this.$router.go(-1);
    },
    Project() {
      return Projectmodel;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/utils/css/global.scss";

.header {
  @include flexLayout-Mixin;
  justify-content: space-between;
  width: 100vw;
  height: vh(80);
  padding: 0 0.7813rem;
  color: #fff;
  @include backgroundColor-Mixin(right, rgba(5, 35, 75), rgba(8, 24, 48));
  box-shadow: 0 5px 10px rgba(0, 0, 0);

  .headerTitle {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    @include cursorPointer-Mixin;

    .ChineseTitle {
      font-size: 0.2188rem;
      font-weight: 700;
      @include letterSpacing-Mixin(0.0469rem);
    }

    .EnglishTitle {
      margin-top: 0.0781rem;
      font-size: 0.1875rem;
      @include letterSpacing-Mixin(0.0156rem);
    }
  }

  .headerTool {
    display: flex;
    align-items: center;

    .toolModule {
      display: flex;
      align-items: center;
      @include letterSpacing-Mixin(0.0156rem);

      .tool-module {
        @include flexLayout-Mixin;
        width: 1.5625rem;
        height: 0.4688rem;
        margin-right: 0.125rem;
        @include background-Mixin("/src/assets/imgs/header-borderIcon.svg");
        font-size: 0.1875rem;
        @include cursorPointer-Mixin;
      }
    }

    .toolTime {
      margin-left: 0.1563rem;
      font-size: 0.2188rem;
      @include customFont-Mixin;

      .day {
        @include cursorPointer-Mixin;
      }

      .year {
        @extend .day;
        margin-top: 0.0781rem;
      }
    }
  }
}
</style>
