<template>
  <div class="containerBox">
    <div class="containerBox-title">
      <div class="title-icon publicPointer">
        <img src="../assets/imgs/containerBox-title-icon.svg" alt="" />
      </div>
      <div class="publicPointer">
        <slot name="titleHeader"></slot>
      </div>
    </div>
    <div class="EchartsModule">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "container-module",
};
</script>

<style scoped lang="scss">
@import "@/utils/css/global.scss";

.containerBox {
  width: 100%;
  margin-bottom: vh(10);

  &:last-child {
    margin-bottom: 0;
  }

  .containerBox-title {
    display: flex;
    position: relative;
    height: vh(40);
    width: 100%;
    align-items: center;
    background: #203441;
    padding-left: 0.2344rem;
    font-size: vh(18);
    font-weight: bold;
    font-style: italic;
    margin-bottom: vh(10);
    @include letterSpacing-Mixin(0.0469rem);
    color: #86d3ff;

    .title-icon {
      @include flexLayout-Mixin;
      width: 0.5469rem;
      line-height: 0.5469rem;
      margin-right: 0.0781rem;

      img {
        width: 40%;
        height: 40%;
      }
    }

    .publicPointer {
      @include cursorPointer-Mixin;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: -0.0938rem;
      left: 0;
      width: 0.2344rem;
      height: 0.0313rem;
      background-color: #86d3ff;
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      bottom: -0.0938rem;
      left: 0;
      width: 0.2344rem;
      height: 0.0313rem;
      background-color: #86d3ff;
    }
  }

  .EchartsModule {
    background-color: #0d1d33;
  }
}
</style>
