<template>
  <div class="smegma">
    <div class="side" ref="sideRef" data-dept="drag">
      <div class="popupBox" ref="popupRef">
        <div class="popupBox-header">
          <!-- 关闭弹窗 -->
          <div class="close" @click="$store.commit('popupState/close', false)">
            <div class="close-img">
              <img src="../assets/imgs/close.svg" alt="" />
            </div>
            <div class="close-title">关闭</div>
          </div>
        </div>
        <div class="popupBox-content">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 引入拖拽元素公共函数
import { dragElementAPI } from "@/utils/tool.js";

export default {
  name: "popup-module",

  mounted() {
    // const popupBox = this.$refs.popupRef;
    // dragElementAPI(popupBox);

    const sideBox = this.$refs.sideRef;
    dragElementAPI(sideBox);
  },
};
</script>

<style lang="scss" scoped>
@import "@/utils/css/global.scss";

.smegma {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.5); // 蒙层
  z-index: 99999999999999999999999999;

  .side {
    position: fixed;
    top: 2.3438rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.0781rem;
    background: #fff;
    border-radius: 0.1563rem;

    .popupBox {
      color: #fff;
      background: url("/src/assets/imgs/Bg.png") no-repeat center center;
      background-size: cover;
      border-radius: 0.1563rem;
      overflow: hidden;

      .popupBox-header {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        height: 0.7813rem;
        padding: 0 0.1875rem;
        background: url("/src/assets/imgs/popup-header-Bg.png") no-repeat top
          center;
        background-size: contain;

        .close {
          display: flex;
          @include cursorPointer-Mixin;

          .close-img {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 0.2344rem;
            height: 0.2344rem;
            margin-right: 0.0781rem;

            img {
              width: 100%;
              height: 100%;
            }
          }
          .close-title {
            font-size: 0.1563rem;
            letter-spacing: 0.0156rem;
          }
        }
      }

      .popupBox-content {
        width: 100%;
        padding: 0 0.1875rem 0.1875rem 0.1875rem;
      }
    }
  }
}
</style>
