<template>
  <div class="skeletonBox">
    <div
      class="skeleton-Go"
      v-for="(ele, i) in skeletonList"
      :key="i"
      :style="{ width: ele + '%' }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "skleton-module",

  data() {
    return {
      skeletonList: [],
    };
  },

  props: {
    skeletonNum: {
      typeof: Number,
      default: 10,
    },
  },

  created() {
    for (let i = 0; i < this.skeletonNum; i++) {
      this.skeletonList.push(Math.floor(Math.random() * 100) + 1);
    }
  },

  beforeDestroy() {
    this.skeletonList = [];
  },
};
</script>

<style lang="scss" scoped>
.skeletonBox {
  width: 100%;

  .skeleton-Go {
    height: 0.1563rem;
    background: #f2f2f2;
    border-radius: 0.0781rem;
    animation: gradient-animation 2s linear infinite;
    background: linear-gradient(to right, #797b7a, #c3c3c3);
    background-size: 400% 100%;
    margin-bottom: 0.1563rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}
</style>
