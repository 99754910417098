const state = {
  messageState: false, // 控制messag组件状态
  messageData: {
    state: false, // 默认false 成功或者失败的状态
    data: null, // 提示信息
  }, // 传递提示信息
};

const mutations = {
  // 打开message组件
  openMessage(state, value) {
    state.messageState = value;
  },
  // 关闭message组件
  closeMessage(state, value) {
    state.messageState = value;
  },
  // message传输数据
  getMessageData(state, value) {
    state.messageData = value;
  },
};

const actions = {};

export default {
  namespaced: true, //添加命名空间,防止变量污染
  state,
  mutations,
  actions,
};
