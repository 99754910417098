const state = {
    refreshState: false, // 刷新状态
};

const mutations = {
    // 强制刷新页面
    refreshAPI(state, value) {
        console.log(value);
        state.refreshState = value;
    },
};

const actions = {};

export default {
    namespaced: true, //添加命名空间,防止变量污染
    state,
    mutations,
    actions,
};