<template>
  <div class="loadingEffect-module">
    <div class="loading"></div>
  </div>
</template>

<script>
export default {
  name: "loadingEffect-module",
};
</script>

<style lang="scss" scoped>
@keyframes rotation {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.loadingEffect-module {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 48px;
    height: 48px;
    border: 3px solid #fff;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    -webkit-animation: rotation 1s linear infinite;
    animation: rotation 1s linear infinite;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 56px;
      height: 56px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-bottom-color: #fff;
    }
  }
}
</style>
