/**
 * 
 * @param {string} deviceType 设备类型
 * @returns {any} 设备假数据
 * 
 * 2024-06-21日 增加mock.js文件
 */
export function falseDataSelection(deviceType) {
    switch (deviceType) {
        case 'met':
            return met;
        case 'wormFlagship':
            return wormFlagship;
        case 'soil':
            return soil;
        case 'spore':
            return spore;
        default:
            return '暂无此设备类型的数据,请自行添加'
    }
}

// 气象设备假数据
const met = {
    data: [{
        humAlarmStatus: 0,
        humName: "风速",
        humUnit: "m/s",
        humValue: 0,
        humValueStr: "0",
        nodeId: 1,
        nodeName: "风力风速",
        nodeType: 1,
        priority: 0,
        temAlarmStatus: 0,
        temName: "风力",
        temUnit: "级",
        temValue: 0,
        temValueStr: "0",
    }, {
        humAlarmStatus: 0,
        humName: "风向",
        humUnit: "%RH",
        humValue: 0,
        humValueStr: "0",
        nodeId: 2,
        nodeName: "风向",
        nodeType: 8,
        priority: 0,
        temAlarmStatus: 0,
        temName: "风向",
        temUnit: "方向",
        temValue: 0,
        temValueStr: "未知",
    }, {
        humAlarmStatus: 0,
        humName: "土壤湿度",
        humUnit: "%RH",
        humValue: 0,
        humValueStr: "0",
        nodeId: 3,
        nodeName: "土壤温湿度",
        nodeType: 1,
        priority: 0,
        temAlarmStatus: 0,
        temName: "土壤温度",
        temUnit: "℃",
        temValue: 0,
        temValueStr: "0",
    }, {
        humAlarmStatus: 0,
        humName: "土壤EC",
        humUnit: "us/cm",
        humValue: 0,
        humValueStr: "0.0",
        nodeId: 4,
        nodeName: "土壤EC",
        nodeType: 3,
        priority: 0,
        temAlarmStatus: 0,
        temName: "土壤PH1",
        temUnit: "",
        temValue: 0,
        temValueStr: "0.0",
    }, {
        humAlarmStatus: 0,
        humName: "土壤湿度2",
        humUnit: "%RH",
        humValue: 0,
        humValueStr: "0.0",
        nodeId: 5,
        nodeName: "雨雪",
        nodeType: 5,
        priority: 0,
        temAlarmStatus: 0,
        temName: "土壤温度2",
        temUnit: "℃",
        temValue: 0,
        temValueStr: "未知",
    }, {
        humAlarmStatus: 0,
        humName: "紫外线",
        humUnit: "级",
        humValue: 0,
        humValueStr: "0.0",
        nodeId: 6,
        nodeName: "紫外线",
        nodeType: 3,
        priority: 0,
        temAlarmStatus: 0,
        temName: "土壤PH2",
        temUnit: "",
        temValue: 0,
        temValueStr: "0.0",
    }, {
        humAlarmStatus: 0,
        humName: "总辐射",
        humUnit: "W/m2",
        humValue: 0,
        humValueStr: "0.0",
        nodeId: 7,
        nodeName: "总辐射",
        nodeType: 3,
        priority: 0,
        temAlarmStatus: 0,
        temName: "土壤温度3",
        temUnit: "℃",
        temValue: 0,
        temValueStr: "0.0",
    }, {
        humAlarmStatus: 0,
        humName: "空气湿度",
        humUnit: "%RH",
        humValue: 0,
        humValueStr: "0.0",
        nodeId: 8,
        nodeName: "空气温湿度",
        nodeType: 1,
        priority: 0,
        temAlarmStatus: 0,
        temName: "空气温度",
        temUnit: "℃",
        temValue: 0,
        temValueStr: "0",
    }]
}

// 虫情设备假数据
const wormFlagship = {
    data: {
        DTime: "2024-06-21 11:30:24",
        attractWorm: "0",
        cell: "100",
        deviceAddr: "0406240027",
        deviceType: "wormFlagship",
        door: "0",
        drying: "0",
        dryingFlap: "0",
        dryingTem: "33.3",
        fillLight: "0",
        illum: "40671",
        insecticide: "0",
        insecticideTem: "33.2",
        lat: "0.0",
        lng: "0.0",
        mode: "1",
        moveWorm: "0",
        rain: "0",
        rainFlap: "0",
        shake: "0",
        status: "online",
        truthStatus: "true",
        wormFlap: "0",
    }
}

// 墒情设备假数据
const soil = {
    data: [{
        humAlarmStatus: 0,
        humName: "第一层土壤湿度",
        humUnit: "%RH",
        humValue: 0,
        humValueStr: "0.0",
        nodeId: 1,
        nodeName: "第一层土壤温湿度",
        nodeType: 1,
        priority: 0,
        temAlarmStatus: 0,
        temName: "第一层土壤温度",
        temUnit: "℃",
        temValue: 0,
        temValueStr: "0.0",
    }]
}

// 孢子设备假数据
const spore = {
    data: {
        AdmissionPortStatus: "0", //吸气口状态(0:关闭 1:打开)
        Altitude: "0.0", //废弃
        BlowVentStatus: "0", //排气口状态(0:关闭 1:打开)
        IamphouseStatus: "0", //光源状态(0l关闭 1:开启)
        Latitude: "0.0", //纬度
        Longitude: "0.0", //经度
        PulseCurrent: "0", //采集的单次脉冲数
        PulseTotal: "81", //采集的累计脉冲数
        SamplingPortStatus: "0", //采样口状态(0:关闭 1::打开)
        SwitchStatus: "0", //废弃
        YMotorDirection: "0", //废弃
        YMotorPulse: "0", //Y轴电机脉冲数(0-55000)
        YMotorStatus: "0", //Y轴电机状态(0:停止 1:运行)
        ZBDMotorPulse: "0", //载玻带单次运动距离的脉冲数
        ZBDMotorStatus: "0", //载玻带电机状态(0:停止 1:运行)
        controlMode: "0", //控制模式 (0:手动 1:自动)
        motorPulse: "0,55000", //Y轴电机脉冲范围(最小值,最大值)
        truthStatus: "true" //废弃
    }
}


/**
 * 2024-06-26 增加假数据
 */

export const arr = [
    { accessToken: 'at.56zf79q5co5mgih16huw6l195k97sf8g-1e8h9q6h3o-1bq2ius-ekwz8ysil', url: 'ezopen://open.ys7.com/FD1526113/1.live', videoName: 'DS-2DE2Q140MY-T/GLSE(FD1526113)' },
    { accessToken: 'at.56zf79q5co5mgih16huw6l195k97sf8g-1e8h9q6h3o-1bq2ius-ekwz8ysil', url: 'ezopen://open.ys7.com/FD1526079/1.live', videoName: 'DS-2DE2Q140MY-T/GLSE(FD1526079)' },
    { accessToken: 'at.56zf79q5co5mgih16huw6l195k97sf8g-1e8h9q6h3o-1bq2ius-ekwz8ysil', url: 'ezopen://open.ys7.com/FD1526069/1.live', videoName: 'DS-2DE2Q140MY-T/GLSE(FD1526069)' },
    { accessToken: 'at.56zf79q5co5mgih16huw6l195k97sf8g-1e8h9q6h3o-1bq2ius-ekwz8ysil', url: 'ezopen://open.ys7.com/FD1526199/1.live', videoName: 'DS-2DE2Q140MY-T/GLSE(FD1526199)' },
    { accessToken: 'at.56zf79q5co5mgih16huw6l195k97sf8g-1e8h9q6h3o-1bq2ius-ekwz8ysil', url: 'ezopen://open.ys7.com/FB7193072/1.live', videoName: 'DS-2DE2Q140MY-T/GLSE(FB7193072)' },
    { accessToken: 'at.56zf79q5co5mgih16huw6l195k97sf8g-1e8h9q6h3o-1bq2ius-ekwz8ysil', url: 'ezopen://open.ys7.com/FC8667758/1.live', videoName: 'DS-2DE2Q140MY-T/GLSE(FC8667758)' },
    { accessToken: 'at.56zf79q5co5mgih16huw6l195k97sf8g-1e8h9q6h3o-1bq2ius-ekwz8ysil', url: 'ezopen://open.ys7.com/FC8667776/1.live', videoName: 'DS-2DE2Q140MY-T/GLSE(FC8667776)' },
    { accessToken: 'at.56zf79q5co5mgih16huw6l195k97sf8g-1e8h9q6h3o-1bq2ius-ekwz8ysil', url: 'ezopen://open.ys7.com/FC8667775/1.live', videoName: 'DS-2DE2Q140MY-T/GLSE(FC8667775)' },
    { accessToken: 'at.56zf79q5co5mgih16huw6l195k97sf8g-1e8h9q6h3o-1bq2ius-ekwz8ysil', url: 'ezopen://open.ys7.com/FC8667668/1.live', videoName: 'DS-2DE2Q140MY-T/GLSE(FC8667668)' },
    { accessToken: 'at.56zf79q5co5mgih16huw6l195k97sf8g-1e8h9q6h3o-1bq2ius-ekwz8ysil', url: 'ezopen://open.ys7.com/FD1526071/1.live', videoName: 'DS-2DE2Q140MY-T/GLSE(FD1526071)' },
    { accessToken: 'at.56zf79q5co5mgih16huw6l195k97sf8g-1e8h9q6h3o-1bq2ius-ekwz8ysil', url: 'ezopen://open.ys7.com/FC8667753/1.live', videoName: 'DS-2DE2Q140MY-T/GLSE(FC8667753)' },
    { accessToken: 'at.56zf79q5co5mgih16huw6l195k97sf8g-1e8h9q6h3o-1bq2ius-ekwz8ysil', url: 'ezopen://open.ys7.com/FC8667731/1.live', videoName: 'DS-2DE2Q140MY-T/GLSE(FC8667731)' },
    { accessToken: 'at.56zf79q5co5mgih16huw6l195k97sf8g-1e8h9q6h3o-1bq2ius-ekwz8ysil', url: 'ezopen://open.ys7.com/FC8667612/1.live', videoName: 'DS-2DE2Q140MY-T/GLSE(FC8667612)' },
    { accessToken: 'at.56zf79q5co5mgih16huw6l195k97sf8g-1e8h9q6h3o-1bq2ius-ekwz8ysil', url: 'ezopen://open.ys7.com/FC8667746/1.live', videoName: 'DS-2DE2Q140MY-T/GLSE(FC8667746)' },
    { accessToken: 'at.56zf79q5co5mgih16huw6l195k97sf8g-1e8h9q6h3o-1bq2ius-ekwz8ysil', url: 'ezopen://open.ys7.com/FD1526072/1.live', videoName: 'DS-2DE2Q140MY-T/GLSE(FD1526072)' },
    { accessToken: 'at.56zf79q5co5mgih16huw6l195k97sf8g-1e8h9q6h3o-1bq2ius-ekwz8ysil', url: 'ezopen://open.ys7.com/FD1526057/1.live', videoName: 'DS-2DE2Q140MY-T/GLSE(FD1526057)' },
    { accessToken: 'at.56zf79q5co5mgih16huw6l195k97sf8g-1e8h9q6h3o-1bq2ius-ekwz8ysil', url: 'ezopen://open.ys7.com/FD1526140/1.live', videoName: 'DS-2DE2Q140MY-T/GLSE(FD1526140)' },
    { accessToken: 'at.56zf79q5co5mgih16huw6l195k97sf8g-1e8h9q6h3o-1bq2ius-ekwz8ysil', url: 'ezopen://open.ys7.com/FD1526070/1.live', videoName: '泵站' },
    { accessToken: 'at.56zf79q5co5mgih16huw6l195k97sf8g-1e8h9q6h3o-1bq2ius-ekwz8ysil', url: 'ezopen://open.ys7.com/FD1526186/1.live', videoName: '泵站' },
    { accessToken: 'at.56zf79q5co5mgih16huw6l195k97sf8g-1e8h9q6h3o-1bq2ius-ekwz8ysil', url: 'ezopen://open.ys7.com/FD1526189/1.live', videoName: '泵站' },
    { accessToken: 'at.56zf79q5co5mgih16huw6l195k97sf8g-1e8h9q6h3o-1bq2ius-ekwz8ysil', url: 'ezopen://open.ys7.com/FD1526061/1.live', videoName: '泵房' },
    { accessToken: 'at.56zf79q5co5mgih16huw6l195k97sf8g-1e8h9q6h3o-1bq2ius-ekwz8ysil', url: 'ezopen://open.ys7.com/FD1526078/1.live', videoName: '泵站' },
    { accessToken: 'at.56zf79q5co5mgih16huw6l195k97sf8g-1e8h9q6h3o-1bq2ius-ekwz8ysil', url: 'ezopen://open.ys7.com/FD1526139/1.live', videoName: '泵站2' },
    { accessToken: 'at.56zf79q5co5mgih16huw6l195k97sf8g-1e8h9q6h3o-1bq2ius-ekwz8ysil', url: 'ezopen://open.ys7.com/FD1526077/1.live', videoName: '泵站' },
    { accessToken: 'at.56zf79q5co5mgih16huw6l195k97sf8g-1e8h9q6h3o-1bq2ius-ekwz8ysil', url: 'ezopen://open.ys7.com/FD1526171/1.live', videoName: 'DS-2DE2Q140MY-T/GLSE(FD1526171)' },
    { accessToken: 'at.56zf79q5co5mgih16huw6l195k97sf8g-1e8h9q6h3o-1bq2ius-ekwz8ysil', url: 'ezopen://open.ys7.com/33011062992407586293:33010067991327570376/1.live', videoName: '任门寨村' },
    { accessToken: 'at.56zf79q5co5mgih16huw6l195k97sf8g-1e8h9q6h3o-1bq2ius-ekwz8ysil', url: 'ezopen://open.ys7.com/33011062992407586293:33011043991327463575/1.live', videoName: '馆陶孙雷寨' },
]