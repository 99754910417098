import http from "../utils/request/http";

/* 用户API接口 */

/**
 *
 * @param {用户-登录} data
 * @returns
 */
export const loginAPI = (data) => {
    return http({
        url: "/api/v2.0/entrance/user/userLogin",
        method: "post",
        data,
    });
};

/**
 *
 * @param {管理平台---获取用户设备} params
 * @returns
 */

export const getsysUserDeviceAPI = (params) => {
    return http({
        url: "/api/v2.0/entrance/device/getsysUserDevice",
        method: "get",
        params,
    });
};

/**
 *
 * @param {管理平台---获取登录用户信息} params
 * @returns
 */

export const getUserAPI = (params) => {
    return http({
        url: "/api/v2.0/entrance/user/getUser",
        method: "get",
        params,
    });
};


/**
 *
 * @param {管理平台---获取设备实时数据} params
 * @returns
 */

export const getRealTimeDataAPI = (params) => {
    return http({
        url: "/api/v2.0/entrance/device/getRealTimeData",
        method: "get",
        params,
    });
};