<template>
  <div>
    <div class="searchBox" v-if="switchType == 1">
      <!-- 查询框 -->
      <div class="queryInputBox">
        <!-- 设备地址 -->
        <div class="contentBox gap">
          <div class="title">设备地址:</div>
          <div class="promptInputBox">
            <input
              type="text"
              placeholder="请输入设备地址"
              class="inputQuery"
              v-model.trim="historyForm.deviceAddr"
            />
            <div class="prompt" v-if="prompt">请输入设备地址</div>
          </div>
        </div>
        <!-- 节点编号 -->
        <div class="contentBox gap">
          <div class="title">节点编号:</div>
          <div class="promptInputBox">
            <input
              type="text"
              placeholder="请输入节点编号"
              class="inputQuery"
              v-model.trim="historyForm.nodeId"
            />
            <div class="prompt" v-if="prompt">请输入节点编号</div>
          </div>
        </div>
        <!-- 开始时间 -->
        <div class="contentBox gap">
          <div class="title">开始时间:</div>
          <div class="promptInputBox">
            <input
              type="datetime-local"
              step="1"
              name="myDateTime"
              class="datetimeInput"
              v-model="historyForm.beginTime"
            />
            <div class="prompt" v-if="prompt">请输入开始时间</div>
          </div>
        </div>
        <!-- 结束时间 -->
        <div class="contentBox">
          <div class="title">结束时间:</div>
          <div class="promptInputBox">
            <input
              type="datetime-local"
              step="1"
              name="myDateTime"
              class="datetimeInput"
              v-model="historyForm.endTime"
            />
            <div class="prompt" v-if="prompt">请输入结束时间</div>
          </div>
        </div>
      </div>
      <!-- 查询按钮 -->
      <div class="queryBtn" @click="searchHistory">查询</div>
    </div>
    <div class="searchBox" v-if="switchType == 2">
      <!-- 查询框 -->
      <div class="queryInputBox">
        <!-- 设备地址 -->
        <div class="contentBox gap">
          <div class="title">设备地址:</div>
          <div class="promptInputBox">
            <input
              type="text"
              placeholder="请输入设备地址"
              class="inputQuery"
              v-model.trim="historyForm.deviceAddr"
            />
            <div class="prompt" v-if="prompt">请输入设备地址</div>
          </div>
        </div>
        <!-- 开始时间 -->
        <div class="contentBox gap">
          <div class="title">开始时间:</div>
          <div class="promptInputBox">
            <input
              type="datetime-local"
              step="1"
              name="myDateTime"
              class="datetimeInput"
              v-model="historyForm.beginTime"
            />
            <div class="prompt" v-if="prompt">请输入开始时间</div>
          </div>
        </div>
        <!-- 结束时间 -->
        <div class="contentBox">
          <div class="title">结束时间:</div>
          <div class="promptInputBox">
            <input
              type="datetime-local"
              step="1"
              name="myDateTime"
              class="datetimeInput"
              v-model="historyForm.endTime"
            />
            <div class="prompt" v-if="prompt">请输入结束时间</div>
          </div>
        </div>
      </div>
      <!-- 查询按钮 -->
      <div class="queryBtn" @click="searchHistory">查询</div>
    </div>
    <div class="searchBox" v-if="switchType == 3">
      <!-- 查询框 -->
      <div class="queryInputBox">
        <!-- 区域id -->
        <div class="contentBox gap">
          <div class="title">区域ID:</div>
          <div class="promptInputBox">
            <select class="deviceTypeSelect" v-model="historyForm.groupId">
              <option value="" disabled selected>请选择区域ID</option>
              <option
                :value="item"
                v-for="(item, index) in userDevice.groupIdList"
                :key="index"
              >
                {{ item }}
              </option>
            </select>
            <div class="prompt" v-if="prompt">请选择区域ID</div>
          </div>
        </div>
        <!-- 开始时间 -->
        <div class="contentBox gap">
          <div class="title">开始时间:</div>
          <div class="promptInputBox">
            <input
              type="datetime-local"
              step="1"
              name="myDateTime"
              class="datetimeInput"
              v-model="historyForm.beginTime"
            />
            <div class="prompt" v-if="prompt">请输入开始时间</div>
          </div>
        </div>
        <!-- 结束时间 -->
        <div class="contentBox">
          <div class="title">结束时间:</div>
          <div class="promptInputBox">
            <input
              type="datetime-local"
              step="1"
              name="myDateTime"
              class="datetimeInput"
              v-model="historyForm.endTime"
            />
            <div class="prompt" v-if="prompt">请输入结束时间</div>
          </div>
        </div>
      </div>
      <!-- 查询 -->
      <div class="queryBtn" @click="searchHistory">查询</div>
    </div>
  </div>
</template>

<script>
// 引入工具函数
import { isEmptyCheckAPI } from "@/utils/tool.js";

export default {
  name: "search-module",

  /**
   * 使用须知:
   * 1.必须传递搜索表单数据 historyForm
   * 2.传递 switchType 可以切换 input的类型
   * 3.searchHistory()方法会触发父组件的自定义事件
   * 4.使用了部分scss公共css样式
   * 5.main.js注册全局公共组件
   * 6.考虑 虫情设备分析报表ID需要axios请求,所以为放入 search搜索公共组件
   *
   * 2023-12-1日封装
   */

  data() {
    return {
      prompt: false, // 提示信息状态
    };
  },

  props: {
    historyForm: {
      typeof: Object, // 接收搜索的数据
      required: true, // 必须传递的参数
    },

    switchType: {
      typeof: [Number, String], // 切换为那种 input类型
      /**
       * 1:气象设备+墒情设备历史记录模块
       * 2:虫情设备历史记录模块+虫情设备分析报表
       * 3:虫情区域统计
       */
      default: 1, // 默认
    },

    userDevice: {
      typeof: Object, // 接收传递的用户设备ID号,用于虫情区域统计
    },
  },

  methods: {
    // 查询
    searchHistory() {
      // 每次查询都设置状态为false 不可见
      this.prompt = false;

      // false 显示提示信息
      if (!isEmptyCheckAPI(this.historyForm)) return (this.prompt = true);

      // true 触发自定义属性进行 axios请求
      this.$emit("historyQuery");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/utils/css/global.scss";

.searchBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: vh(40);

  .queryInputBox {
    display: flex;
    align-items: center;

    // 主题内容
    .contentBox {
      display: flex;
      align-items: center;
      font-size: 0.1563rem;
      font-weight: 700;
      color: #ffff;
      letter-spacing: 0.0156rem;

      .title {
        margin-right: 0.1563rem;
      }

      .promptInputBox {
        position: relative;

        // input框的css样式
        .inputQuery {
          @include input-Mixin;
        }

        .inputQuery::placeholder {
          font-size: 0.125rem;
          line-height: 0.3906rem;
          color: #fff;
        }

        // 时间选择器的css样式
        .datetimeInput {
          @include timeSelector-Mixin;
        }

        // select框的css样式
        .deviceTypeSelect {
          @include deviceTypeSelect-Mixin;
        }

        // 提示信息
        .prompt {
          @include prompt-Mixin;
        }
      }
    }

    // 距离
    .gap {
      margin-right: 0.1563rem;
    }
  }

  // 查询按钮内容
  .queryBtn {
    @include Btn-Mixin;
  }
}
</style>
