// 路由守卫
import router from "./router";

// 获取vuex里的token
import store from "./store";


// 前置路由守卫
router.beforeEach((to, from, next) => {
    if (store.getters.token) {
        if (to.path === '/') {
            next()
        } else {
            next()
        }
    } else {
        next()
    }
});

// 路由后置守卫

router.afterEach(() => {});