// 引入router
import router from "@/router";

const state = {
    token: localStorage.getItem("token"), // token
    logintimer: localStorage.getItem("logintimer"), // 登录时间
};

const mutations = {
    // 获取token
    getToken(state, value) {
        state.token = value;
        localStorage.setItem("token", value);
    },
    // 清除token
    clearToken(state) {
        state.token = null;
        localStorage.removeItem("token");
        router.push('/')
    },
    // 时间戳
    timer(state, value) {
        state.logintimer = value;
        localStorage.setItem("logintimer", value);
    },
};

const actions = {};

export default {
    namespaced: true, //添加命名空间,防止变量污染
    state,
    mutations,
    actions,
};