<template>
  <div
    class="weather-PublicRealtime"
    ref="PublicRealtime_Ref"
    :class="props_Status == true ? 'props_height' : ''"
  >
    <vue-seamless-scroll
      :data="copyData"
      :class-option="optionHover"
      ref="vueSeamlessScroll"
      class="seamless-warp"
      :class="props_Status == true ? 'props_seamless_warp' : ''"
    >
      <div
        class="dataRecord"
        ref="dataRecord_Ref"
        v-for="(item, i) in dataList"
        :key="i"
      >
        <!-- 设备编号 + 设备在线状态 -->
        <div class="device_Data">
          <span class="device_Data_Name">{{ item.deviceName }}</span>
          <!-- 切换在线状态的颜色 line_online line_offline -->
          <div
            class="line"
            :class="item.status === 'online' ? 'line_online' : 'line_offline'"
          ></div>
          <!-- 切换在线状态的颜色 on_line off_line -->
          <span
            class="device_Data_Status"
            :class="item.status === 'online' ? 'on_line' : 'off_line'"
          >
            设备{{
              item.status === "online"
                ? "在线"
                : item.status === "offline"
                ? "离线"
                : "警报"
            }}
          </span>
        </div>
        <!-- 设备模块的状态 -->
        <div class="real_time_Status" v-if="props_realTime_Status == 0">
          <div
            class="real_time_status"
            v-for="(ele, index) in item.data"
            :key="index"
          >
            <div class="status_title">{{ ele.nodeName }}</div>
            <div class="status_value" v-if="ele.nodeType == 1">
              {{ ele.humValueStr }}{{ ele.humUnit }}
              -
              {{ ele.temValueStr }}{{ ele.temUnit }}
            </div>
            <div class="status_value" v-else-if="ele.nodeType == 2">
              {{ ele.temValueStr }}{{ ele.temUnit }}
            </div>
            <div class="status_value" v-else-if="ele.nodeType == 3">
              {{ ele.humValueStr }}{{ ele.humUnit }}
            </div>
            <div class="status_value" v-else>
              {{ ele.temValueStr }}
            </div>
          </div>
        </div>
        <div class="device_model_status" v-else-if="props_realTime_Status == 1">
          <div class="real_time_status">
            <div class="status_title">补光灯</div>
            <div class="status_value">
              {{ deviceStatus(item.data.fillLight) }}
            </div>
          </div>
          <div class="real_time_status">
            <div class="status_title">光照度</div>
            <div class="status_value">{{ item.data.illum }} Lux</div>
          </div>
          <div class="real_time_status">
            <div class="status_title">降雨状态</div>
            <div class="status_value">
              {{ item.data.rain == "0" ? "无雨" : "有雨" }}
            </div>
          </div>
          <div class="real_time_status">
            <div class="status_title">杀虫挡板</div>
            <div class="status_value">
              {{ deviceStatus(item.data.wormFlap) }}
            </div>
          </div>
          <div class="real_time_status">
            <div class="status_title">震动装置</div>
            <div class="status_value">{{ deviceStatus(item.data.shake) }}</div>
          </div>
          <div class="real_time_status">
            <div class="status_title">烘干挡板</div>
            <div class="status_value">
              {{ deviceStatus(item.data.dryingFlap) }}
            </div>
          </div>
          <div class="real_time_status">
            <div class="status_title">杀虫控制</div>
            <div class="status_value">
              {{ deviceStatus(item.data.insecticide) }}
            </div>
          </div>
          <div class="real_time_status">
            <div class="status_title">移虫挡板</div>
            <div class="status_value">
              {{ deviceStatus(item.data.moveWorm) }}
            </div>
          </div>
          <div class="real_time_status">
            <div class="status_title">运行模式</div>
            <div class="status_value">
              {{ item.data.mode == "0" ? "手动" : "自动" }}
            </div>
          </div>
          <div class="real_time_status">
            <div class="status_title">烘干控制</div>
            <div class="status_value">{{ deviceStatus(item.data.drying) }}</div>
          </div>
          <div class="real_time_status">
            <div class="status_title">虫雨挡板</div>
            <div class="status_value">
              {{ deviceStatus(item.data.rainFlap) }}
            </div>
          </div>
          <div class="real_time_status">
            <div class="status_title">杀虫仓温度</div>
            <div class="status_value">{{ item.data.insecticideTem }} °C</div>
          </div>
          <div class="real_time_status">
            <div class="status_title">烘干仓温度</div>
            <div class="status_value">{{ item.data.dryingTem }} °C</div>
          </div>
          <div class="real_time_status">
            <div class="status_title">诱虫灯状态</div>
            <div class="status_value">
              {{ deviceStatus(item.data.attractWorm) }}
            </div>
          </div>
        </div>
        <div class="device_model_status" v-else-if="props_realTime_Status == 2">
          <div class="real_time_status">
            <div class="status_title">控制模式</div>
            <div class="status_value">
              {{ item.data.controlMode == 0 ? "手动" : "自动" }}
            </div>
          </div>
          <div class="real_time_status">
            <div class="status_title">载玻带单次运动距离的脉冲数</div>
            <div class="status_value">
              {{ item.data.ZBDMotorPulse }}
            </div>
          </div>
          <div class="real_time_status">
            <div class="status_title">排气口状态</div>
            <div class="status_value">
              {{ item.data.BlowVentStatus == 0 ? "关闭" : "打开" }}
            </div>
          </div>
          <div class="real_time_status">
            <div class="status_title">光源状态</div>
            <div class="status_value">
              {{ item.data.IamphouseStatus == 0 ? "关闭" : "开启" }}
            </div>
          </div>
          <div class="real_time_status">
            <div class="status_title">采样口状态</div>
            <div class="status_value">
              {{ item.data.SamplingPortStatus == 0 ? "关闭" : "打开" }}
            </div>
          </div>
          <div class="real_time_status">
            <div class="status_title">Y轴电机状态</div>
            <div class="status_value">
              {{ item.data.YMotorStatus == 0 ? "停止" : "运行" }}
            </div>
          </div>
          <div class="real_time_status">
            <div class="status_title">吸气口状态</div>
            <div class="status_value">
              {{ item.data.AdmissionPortStatus == 0 ? "关闭" : "打开" }}
            </div>
          </div>
          <div class="real_time_status">
            <div class="status_title">脉冲范围</div>
            <div class="status_value">
              {{ item.data.motorPulse }}
            </div>
          </div>
          <div class="real_time_status">
            <div class="status_title">脉冲数</div>
            <div class="status_value">
              {{ item.data.PulseTotal }}
            </div>
          </div>
          <div class="real_time_status">
            <div class="status_title">载玻带点击状态</div>
            <div class="status_value">
              {{ item.data.ZBDMotorStatus == 0 ? "停止" : "运行" }}
            </div>
          </div>
          <div class="real_time_status">
            <div class="status_title">Y轴电机方法</div>
            <div class="status_value">
              {{ item.data.YMotorDirection == 0 ? "前进" : "后退" }}
            </div>
          </div>
        </div>
      </div>
    </vue-seamless-scroll>
  </div>
</template>

<script>
export default {
  name: "realtimeDataDisplay-module",
  /**
   * @param {Array} realTime_Data 传入循环的数据
   * @param {Boolean} props_Status 传入设备高度(默认400)
   * @param {Boolean} props_realTime_Status 传入设备状态
   */
  props: {
    realTime_Data: {
      type: Array,
    },
    props_Status: {
      type: Boolean,
      default: false, //默认为400(死)
      /**
       * 只能在style标签中添加类名,完成适配
       */
    },
    props_realTime_Status: {
      type: [Number, String], //默认展示 气象设备墒情设备数据
      default: 0,
    },
  },

  data() {
    return {
      dataList: [],
      optionHover: null, // vue-samless-scroll插件的实例方法
      copyData: [], // vu-samless-scroll插件需要复制的数据
    };
  },

  methods: {
    deviceStatus(value) {
      return value == "0" ? "关闭" : "开启";
    },
  },

  watch: {
    realTime_Data: {
      handler(newValue) {
        if (newValue.length === 0) return;
        this.dataList = newValue;
        //当Dom元素挂载后执行
        this.$nextTick(() => {
          const srcollLis_Dom = this.$refs.PublicRealtime_Ref;

          const scrollList_DomStyle =
            this.$refs.dataRecord_Ref[this.$refs.dataRecord_Ref.length - 1];

          // 给vue-seamless-scroll组件添加实例属性
          this.optionHover = {
            limitMoveNum: this.dataList.length - 1,
            step: 1,
            direction: 1, // 0向下 1向上 2向左 3向右
            openWatch: true, // 开启数据实时监控刷新dom
            singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
            singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
            // autoPlay为false则不自动滚动，反之则开发自动滚动
            autoPlay:
              scrollList_DomStyle.offsetTop > srcollLis_Dom.clientHeight,
          };

          if (this.optionHover.autoPlay) {
            /***
             * vue-samless-scroll插件的data属性有值会自动复制一份并进行渲染
             * 所以当表格滚动时复制,反之不需要复制
             */
            this.copyData = this.dataList;
          }
        });
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scopd>
@import "@/utils/css/global.scss";

/* 表格滚动组件高度 */
.seamless-warp {
  width: 100%;
  height: vh(370);
  overflow: hidden;
}

.weather-PublicRealtime {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  height: vh(400);
  padding: vh(10) !important;

  .dataRecord {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: black;
    // background: #fff;
    background-image: linear-gradient(135deg, #fdfcfb 0%, #f5f7fa 0%);
    padding: vh(10);
    border-radius: vh(10);
    margin: vh(10) 0;
    width: 100%;

    .device_Data {
      position: relative;
      display: flex;
      align-items: center;
      font-size: vh(14);
      font-weight: 800;

      .line {
        width: vw(50);
        height: vh(5);
        border-radius: vh(5);
        margin: 0 vw(15);
      }

      .on_line {
        color: aqua;
      }
      .line_online {
        background-image: linear-gradient(to right, #92fe9d 0%, #00c9ff 100%);
      }

      .off_line {
        color: red;
      }
      .line_offline {
        background-image: linear-gradient(
          -225deg,
          #ffe29f 0%,
          #ffa99f 48%,
          #ff719a 100%
        );
      }
    }

    .real_time_Status {
      display: flex;
      flex-wrap: wrap;
      margin-top: vw(10);
      align-items: center;

      .real_time_status {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        background: #aaaaaa;
        border-radius: vh(8);
        letter-spacing: 2px;
        margin-bottom: vh(10);

        margin-right: vh(15);
        padding: vh(10) vw(10);
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }
        .status_title {
          font-weight: 700;
          font-size: vh(12);
          color: black;
        }
        .status_value {
          font-size: vh(10);
          color: aquamarine;
          margin-top: vh(5);
        }
      }
    }

    .device_model_status {
      display: flex;
      margin-top: vw(10);
      flex-wrap: wrap;

      .real_time_status {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        background: #aaaaaa;
        border-radius: vh(10);
        margin-right: vw(5);
        margin-bottom: vh(5);
        padding: vh(15) vw(25);
        cursor: pointer;

        .status_title {
          font-weight: 700;
          font-size: vh(12);
          color: black;
          margin-bottom: vh(5);
        }
        .status_value {
          font-size: vh(10);
          color: aquamarine;
        }
      }
    }
  }
}

.props_height {
  height: vh(890);
}
.props_seamless_warp {
  height: vh(860);
}
</style>
