/**
 * 注册全局公共组件
 * main.js引入注册
 * 组件名以 Components为后缀的是 全局公共组件
 * 
 * 
 * @description Loading加载效果请以 loadingEffectComponnts 组件为准 ----> 2024.1.5
 */

// 头部公共组件
import headerComponents from "@/components/header-module.vue";

// 容器公共组件
import containerComponents from "@/components/container-module.vue";

// 消息提示公共组件
import messageComponents from "@/components/message-module.vue";

// 弹窗公共组件
import popupComponents from "@/components/popup-module.vue";

// 地图公共组件
import mapComponents from "@/components/map-module.vue";

// loading加载公共组件
import loadingComponents from "@/components/loading-module.vue";

// 骨架屏公共组件
import skeletonComponents from "@/components/skeleton-module.vue";

// 搜索公共组件
import searchComponents from "@/components/search-module.vue";

// 分页公共组件
import paginationComponents from "@/components/pagination-module.vue";

// 弹窗分页公共组件
import popupPaginationComponents from "@/components/popupPagination-module.vue";

// 引入实时设备搜索组件
import realtimeDeviceSearchComponents from "@/components/realtimeDeviceSearch-module.vue"

// 引入实时设备表单组件
import realtimeDeviceFormComponnts from "@/components/realtimeDeviceForm-module.vue"

// 引入实时数据展示组件
import realtimeDataDisplayComponnts from "@/components/realtimeDataDisplay-module.vue"

// 引入Loading加载组件
import loadingEffectComponnts from "@/components/loadingEffect-module.vue";

// 引入图片预览组件
import picturePrevewComponnts from "@/components/picturePreview-module.vue";

// 向外暴露组件名称,便于注册
export default {
    headerComponents,
    containerComponents,
    messageComponents,
    popupComponents,
    mapComponents,
    loadingComponents,
    skeletonComponents,
    searchComponents,
    paginationComponents,
    popupPaginationComponents,
    realtimeDeviceSearchComponents,
    realtimeDeviceFormComponnts,
    realtimeDataDisplayComponnts,
    loadingEffectComponnts,
    picturePrevewComponnts
};