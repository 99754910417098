<template>
  <div class="realtimeDeviceSearch">
    <div class="search_Box">
      <!-- 设备名称 -->
      <label for="search_inputID" class="search_label">
        <span class="search_title">设备名称:</span>
        <input
          v-model="search_Form.deviceName"
          placeholder="请输入设备名称"
          type="text"
          id="search_inputID"
          class="search_input"
        />
      </label>
    </div>
    <!-- 查询 -->
    <div class="Btn">
      <button class="search_BtnBox" @click="search_Btn">查询</button>
      <button class="search_BtnBox clear" @click="clear_searchRecords">
        清除查询记录
      </button>
    </div>
  </div>
</template>

<script>
//引入工具函数
import { messagePromptAPI } from "@/utils/tool.js";

export default {
  name: "realtimeDeviceSearch-module",
  props: {
    contrast_List: {
      typeof: Array,
    },
  },

  data() {
    return {
      search_Form: {
        deviceName: "", //设备名称
      },
    };
  },

  methods: {
    //查询
    search_Btn() {
      if (this.search_Form.deviceName === "") {
        messagePromptAPI({ code: 10, message: "请将信息填写完整" });
        return this.$emit("sarch_Results", []);
      }

      let search_List = [];

      search_List = this.contrast_List?.filter((item) => {
        if (item.deviceName === this.search_Form.deviceName) {
          return item;
        }
      });

      search_List.length === 0
        ? messagePromptAPI({ code: 10, message: "暂无数据" })
        : this.$emit("sarch_Results", search_List);
    },

    //清除查询记录
    clear_searchRecords() {
      this.search_Form.deviceName = "";
      this.$emit("sarch_Results", []);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/utils/css/global.scss";

.realtimeDeviceSearch {
  display: flex;
  justify-content: space-around;
  font-size: vh(15);
  color: #fff;

  .search_Box {
    width: 100%;
    display: flex;
    align-items: center;

    .search_label {
      display: flex;
      align-items: center;
      margin-right: vw(15);

      &:last-child {
        margin-right: 0;
      }

      .search_title {
        font-size: vh(16);
        font-weight: 700;
        margin-right: vw(10);
      }

      .search_input {
        width: vw(150);
        height: vh(35);
        border-radius: vh(5);
        font-size: vh(12);
        outline: none;
        padding-left: vw(5);
        border: vh(1) solid #3e77c3;
        background: #101722;
        color: #fff;
      }
    }
  }

  .Btn {
    display: flex;

    .search_BtnBox {
      width: vw(80);
      height: vh(35);
      border-radius: vh(8);
      font-size: vh(12);
      cursor: pointer;
      border: vh(1) solid #3e77c3;
      color: #fff;
      background: #101722;

      &:active {
        transform: translateY(2px);
      }
    }

    .clear {
      margin-left: vw(10);
      color: red;
    }
  }
}
</style>
