import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [{
        path: "/",
        component: () =>
            import ("@/views/optionPage/option-page.vue"),
    },
    {
        path: "/layoutIndex",
        component: () =>
            import ("@/views/layoutPage/layout-page.vue"),
    },
    {
        path: "/controller",
        component: () =>
            import ("@/views/controllerPage/controller-page.vue"),
    },
    {
        path: "/soil",
        component: () =>
            import ("@/views/soilPage/soil-page.vue"),
    },
    {
        path: "/weather",
        component: () =>
            import ("@/views/weatherPage/weather-page.vue"),
    },
    {
        path: "/insect",
        component: () =>
            import ("@/views/insectPage/insect-page.vue"),
    },
    {
        path: '/spore',
        component: () =>
            import ('@/views/sporePage/spore-page.vue')
    },
    {
        path: "/monitor",
        component: () =>
            import ("@/views/monitorPage/monitor-page.vue"),
    },
    {
        path: "/geography",
        component: () =>
            import ("@/views/geographyPage/geography-page.vue"),
    },
    {
        path: "/machine",
        component: () =>
            import ("@/views/machinePage/machine-page.vue"),
    },
    {
        path: "*",
        name: "/404",
        component: () =>
            import ("@/views/notHave/notHave-page.vue"),
    },
];

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes,
});

export default router;