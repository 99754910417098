<template>
  <div class="paginationBox">
    <!-- 总数据 -->
    <div class="total">
      共<i> {{ total }} </i>条数据
    </div>

    <!-- 上一页 -->
    <button
      class="left"
      :disabled="prevClickDisable"
      :class="prevClickDisable ? 'disabledStyle' : ''"
      @click="prevClick"
    >
      ←
    </button>

    <!-- 页码 -->
    <div class="center">
      <!-- 页码 -->
      <div class="pages">
        第<i>{{ pages }} </i>页
      </div>
    </div>

    <!-- 下一页 -->
    <button
      class="right"
      :disabled="nextClickDisable"
      :class="nextClickDisable ? 'disabledStyle' : ''"
      @click="nextClick"
    >
      →
    </button>
  </div>
</template>

<script>
import { number } from "echarts";

export default {
  name: "popupPagination-module",

  /* 弹窗公共分页组件 */

  props: {
    total: {
      typeof: [Number, String], // 数据总数
    },
    totalItems: {
      typeof: [Number, String], // 数据总页数
    },
    pages: {
      typeof: [Number, String], // 页数
    },
    numType: {
      typeof: [number, String], // 类型
      default: 0, // 默认为0
    },
  },

  methods: {
    /* 下一页 */
    nextClick() {
      if (this.numType == 0) return this.$emit("nextClick", 12);
      this.$emit("nextClick", 6);
    },

    /* 上一页 */
    prevClick() {
      if (this.numType == 0) return this.$emit("prevClick", 12);
      this.$emit("prevClick", 6);
    },
  },

  computed: {
    /***
     * 分页符是否禁用
     * button true为禁用状态
     * >1返回false,解除button禁用状态
     */

    /* 上一页 */
    prevClickDisable() {
      if (this.pages > 1) {
        return false;
      } else {
        return true;
      }
    },

    /* 下一页 */
    nextClickDisable() {
      if (this.pages == this.totalItems) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/utils/css/global.scss";

.paginationBox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  // 总数
  .total {
    font-size: 0.1563rem;
    font-weight: 600;
    letter-spacing: 0.0156rem;
    margin-right: 0.1875rem;

    i {
      font-style: normal;
      margin: 0 0.0469rem;
      @include cursorPointer-Mixin;
    }
  }

  //页码
  .center {
    display: flex;
    align-items: center;
    margin: 0 0.2344rem;

    .pages {
      font-size: 0.1563rem;
      font-weight: 600;
      margin: 0 0.0938rem;
      @include cursorPointer-Mixin;

      i {
        font-style: normal;
        margin: 0 0.0781rem;
      }
    }
  }

  // 按钮样式
  .left,
  .right {
    width: 0.4688rem;
    height: 0.4688rem;
    font-size: 0.1563rem;
    color: black;
    background: #f4f4f5;
    border: 0;
    border-radius: 0.0781rem;
    @include cursorPointer-Mixin;

    &:active {
      @include mouseup-Mixin;
    }
  }

  // button的禁用状态样式
  .disabledStyle {
    cursor: not-allowed;
  }
}
</style>
