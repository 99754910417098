import Vue from "vue";
import Vuex from "vuex";

// 引入模块
import token from "./modules/token.js";
import weatherData from "./modules/weatherData.js";
import popupState from "./modules/popupState.js";
import message from "./modules/message.js";
import refresh from "./modules/refresh.js";

// 引入 getters 模块(vuex的计算属性)
import getters from "@/store/getters.js";

Vue.use(Vuex);

// vuex
const store = new Vuex.Store({
  modules: {
    token, // token
    weatherData, // 传递数据(不要在意名字)
    popupState, // 弹窗组件
    message, // 消息组件
    refresh, // 刷新option页面
  },
  getters,
});

export default store;
