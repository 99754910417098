<template>
  <div class="picturePreview-module" v-if="url !== ''">
    <div class="close_btn" @click="url = ''">&times;</div>
    <div class="preview">
      <img :src="url" class="preview_img" />
    </div>
  </div>
</template>

<script>
export default {
  name: "picturePreview-module",

  data() {
    return {
      url: "", //图片预览地址
    };
  },

  methods: {
    open_picturePreview(url) {
      this.url = url;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/utils/css/global.scss";

.picturePreview-module {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: vh(20);
  border-radius: vh(12);
  background-color: rgba(0, 0, 0, 0.8);

  .close_btn {
    width: 100%;
    font-size: vh(40);
    font-weight: 700;
    text-align: right;
    cursor: pointer;
    color: #dadada;

    &:hover {
      color: #ffffff;
    }
  }

  .preview {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .preview_img {
      width: vh(350);
      height: vh(350);
    }
  }
}
</style>
