<template>
  <!-- 地图模块 -->
  <div class="module">
    <div class="map">
      <!-- 地图 -->
      <div id="map" class="map-container"></div>
    </div>
    <!-- loading加载效果 -->
    <loadingComponents v-show="loading" />
  </div>
</template>

<script>
// 引入高德地图SDK
import AMapLoader from "@amap/amap-jsapi-loader";

// 引入 安徽省含山县地理坐标数据
import { data } from "@/utils/map.js";
// 引入地图地理坐标数据
import Projectmodel from "@/utils/env";

// 高德地图秘钥
window._AMapSecurityConfig = {
  securityJsCode: "5ce6153e88e0c89e71515341c869df99", // '「申请的安全密钥」',
};

export default {
  name: "map-module",

  props: {
    /**
     * 设备经纬度
     */
    props_geoCoordinatesList: {
      type: Array, //经纬度列表
      default: () => [
        {
          lng: 0,
          lat: 0,
          deviceName: "0",
          deviceAddr: "0",
        },
      ], //默认空数组

      /**
       * 设备的数据格式如下:
       * [
       *  { lng: 115.28745, lat: 36.55407, deviceName: "20027291", deviceAddr: "20027291",deviceType:"weather"},
       *  { lng: 115.19468, lat: 36.47915, deviceName: "20027292", deviceAddr: "20027292",deviceType:"weather"},
       *  { lng: 115.13261, lat: 36.47403, deviceName: "20027293", deviceAddr: "20027293",deviceType:"weather"},
       * ]
       */
    },

    props_initState: {
      type: Boolean,
      default: false, //默认 mounted生命周期渲染
    },

    props_promptTitle: {
      type: String,
      default: "查看设备详细信息",
    },

    props_zoom: {
      type: Boolean,
      default: false, //默认缩放比例为10
    },

    basic_data: {
      type: Array,
      default: () => [
        {
          applat: 0,
          applng: 0,
          id: 0,
          nickname: "",
          online: 0,
          sitename: "",
          sn: 0,
          sumEle: "0",
          sumWater: "0",
        },
      ],
    }, //设备基础数据
  },

  data() {
    return {
      list: [
        { name: "灌溉设备", id: 0, value: "aio" },
        { name: "墒情设备", id: 1, value: "soil" },
        { name: "虫情设备", id: 2, value: "wormFlagship" },
        { name: "孢子设备", id: 3, value: "sproe" },
        { name: "气象设备", id: 4, value: "met" },
      ], // 设备选择
      /* ------------ */
      map: null, //高德地图
      dataMap: Projectmodel.mapState, //地理数据
      loading: true, // 显示
      AMap: null, // 高德地图AMap实例

      geoCoordinatesList: [], //地图标记点
      baiscDataList: [], //地图标记点
    };
  },

  mounted() {
    if (!this.props_initState) {
      this.initAMap();
    }
  },

  methods: {
    // 初始化 高德地图SDK
    initAMap(id = 0) {
      // !!!安全密钥及开发者key均为个人~(2023.10.07日创建高德地图SDK)
      AMapLoader.load({
        key: "61fb4dec35fd6288cc1c6a5d5f90d103", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "1.4.15", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.ToolBar", "AMap.Scale", "AMap.ControlBar"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.AMap = AMap;
          // 一.展示地图
          this.mapShow(AMap);
          // 二、地图配置
          this.mapDeploy(AMap);
          // 三、地图标记--->axios请求数据
          this.mark(AMap);

          id === 0 ? this.mark(AMap) : this.markLabel(AMap);

          // 四、去除loading加载效果
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          console.log(`高德地图出错了`);
        });
    },

    // 1、地图展示
    mapShow(AMap) {
      // 创建卫星图层
      var satellite = new AMap.TileLayer.Satellite();
      // 创建路网图层
      var roadNet = new AMap.TileLayer.RoadNet();
      // 将图层添加到地图
      this.map = new AMap.Map("map", {
        pitch: 45, // 地图俯仰角度，有效范围 0 度- 83 度
        viewMode: "3D", // 地图模式
        zoom: this.props_zoom === true ? 11.25 : 10, // 初始化地图级别(放大比例)
        center: Projectmodel.geographicLocation, // 地图地理位置
        doubleClickZoom: false, // 取消地图双击放大效果
        // layers: [satellite, roadNet],
      });
      this.map.addControl(new AMap.ToolBar());
      this.map.addControl(new AMap.Scale());
      this.map.addControl(new AMap.ControlBar());
    },

    // 2、地图配置
    mapDeploy(AMap) {
      // 二.地图配置( 设置安徽省含山县地理 )
      const pathArr = [[this.dataMap]]; // 引入 含山县地理坐标数据
      const polygon = new AMap.Polygon({
        path: pathArr,
        fillColor: "#ccebc5", // 多边形填充颜色
        strokeOpacity: 1, // 线条透明度
        fillOpacity: 0.5, //填充透明度
        strokeColor: "#2b8cbe", // 线条颜色
        strokeWeight: 1, //线条宽度
        strokeStyle: "dashed", // 线样式
        strokeDasharray: [5, 5], //轮廓的虚线和间隙的样式
      });
      // 鼠标移入更改样式
      polygon.on("mouseover", () => {
        polygon.setOptions({
          fillOpacity: 1,
          fillColor: "",
          strokeColor: "black",
          strokeWeight: 3,
        });
      });
      // 鼠标移出恢复样式
      polygon.on("mouseout", () => {
        polygon.setOptions({
          fillOpacity: 0.5,
          fillColor: "#ccebc5",
          strokeColor: "#2b8cbe",
          strokeWeight: 1,
        });
      });
      this.map.add(polygon);
    },

    // 3、地图标记 --- 数字化管理平台
    mark(AMap) {
      let arr = [];
      /**
       * 气象设备icon:蓝色
       * 墒情设备icon:褐色
       * 虫情设备icon:深绿色
       */
      for (let i = 0; i < this.geoCoordinatesList.length; i++) {
        var image = this.convert(this.geoCoordinatesList[i].deviceType);

        let marker = new AMap.Marker({
          // position: new AMap.LngLat(115.28745, 36.5541), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          position: [
            this.geoCoordinatesList[i].lng,
            this.geoCoordinatesList[i].lat,
          ],
          title: this.geoCoordinatesList[i].deviceName,
          test: i,
          icon: new AMap.Icon({
            size: new AMap.Size(20, 20), //图标尺寸
            image: require(`../assets/imgs/大屏/${image}.png`), //Icon 的图像
            imageSize: new AMap.Size(20, 20), //根据所设置的大小拉伸或压缩图片
          }),
        });
        arr.push(marker);
        marker.on("click", (e) => {
          // 获取Ajax数据
          const info = this.geoCoordinatesList[i];
          // 打开信息窗口
          this.prompt(e, AMap, marker, info);
        });
      }
      this.map.add(arr);
    },

    // 3.1、地图标记 --- 智慧种植管理系统
    markLabel(AMap) {
      let arr = [];

      this.baiscDataList.forEach((item, index) => {
        let marker = new AMap.Marker({
          position: [item.applng, item.applat],
          title: item.nickname,
          test: index,
        });

        arr.push(marker);

        marker.on("click", (e) => {
          // 获取Ajax数据
          const info = this.baiscDataList[index];
          // 打开信息窗口
          this.prompt(e, AMap, marker, info, 1);
        });
      });

      this.map.add(arr);
    },

    // 4、悬浮窗
    prompt(e, AMap, marker, info, id = 0) {
      const that = this;

      // 折线的节点坐标数组，每个元素为 AMap.LngLat 对象
      var content = [];
      if (id === 0) {
        // 悬浮窗内容
        content.push(
          `
            <div style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size:.1875rem;
              margin:  .125rem 0;
              color:black"
            >
              <div style="
              border: 0.0156rem solid #dadee3;
              padding: .0781rem 0.1563rem;
              border-radius: 0.0781rem 0 0 0.0781rem;
              background-color:#e9ecef">设备地址</div>

              <div style="flex:2;
                border-radius: 0 0.0781rem 0.0781rem 0;
                background-color:#ffffff;
                padding: .0781rem;
                border: 0.0156rem solid #dadee3;
                border-left: 0;
                padding-left:0.1563rem;">${info.deviceAddr}</div>
            </div>
            `
        );
        content.push(
          `
           <div style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size:.1875rem;
              margin:  .125rem 0;
              color:black"
            >
              <div style="
              border: 0.0156rem solid #dadee3;
              padding: .0781rem 0.1563rem;
              border-radius: 0.0781rem 0 0 0.0781rem;
              background-color:#e9ecef">设备名称</div>

              <div style="flex:2;
                border-radius: 0 0.0781rem 0.0781rem 0;
                background-color:#ffffff;
                padding: .0781rem;
                border: 0.0156rem solid #dadee3;
                border-left: 0;
                padding-left:0.1563rem;overflow:hidden">${info.deviceName}</div>
            </div>
            `
        );
        content.push(
          `
           <div style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size:.1875rem;
              margin:  .125rem 0;
              color:black"
            >
              <div style="
              border: 0.0156rem solid #dadee3;
              padding: .0781rem 0.1563rem;
              border-radius: 0.0781rem 0 0 0.0781rem;
              background-color:#e9ecef">设备类型</div>

              <div style="flex:2;
                border-radius: 0 0.0781rem 0.0781rem 0;
                background-color:#ffffff;
                padding: .0781rem;
                border: 0.0156rem solid #dadee3;
                border-left: 0;
                padding-left:0.1563rem;overflow:hidden">
                 ${deviceTypes(info.deviceType)}
                </div>
            </div>
            `
        );
        content.push(
          `
            <div style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size:.1875rem;
              margin:  .125rem 0;
              color:black"
            >
              <div style="
              border: 0.0156rem solid #dadee3;
              padding: .0781rem 0.1563rem;
              border-radius: 0.0781rem 0 0 0.0781rem;
              background-color:#e9ecef">经度纬度</div>

              <div style="flex:2;
                border-radius: 0 0.0781rem 0.0781rem 0;
                background-color:#ffffff;
                padding: .0781rem;
                border: 0.0156rem solid #dadee3;
                border-left: 0;
                padding-left:0.1563rem;">${info.lng},${info.lat}</div>
            </div>
            `
        );
      } else if (id === 1) {
        content.push(
          `
            <div style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size:.1875rem;
              margin:  .125rem 0;
              color:black"
            >
              <div style="
              border: 0.0156rem solid #dadee3;
              padding: .0781rem 0.1563rem;
              border-radius: 0.0781rem 0 0 0.0781rem;
              background-color:#e9ecef">设备别名</div>

              <div style="flex:2;
                border-radius: 0 0.0781rem 0.0781rem 0;
                background-color:#ffffff;
                padding: .0781rem;
                border: 0.0156rem solid #dadee3;
                border-left: 0;
                padding-left:0.1563rem;">
                ${info.nickname}
                </div>
            </div>
            `
        );
        content.push(
          `
           <div style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size:.1875rem;
              margin:  .125rem 0;
              color:black"
            >
              <div style="
              border: 0.0156rem solid #dadee3;
              padding: .0781rem 0.1563rem;
              border-radius: 0.0781rem 0 0 0.0781rem;
              background-color:#e9ecef">站点名称</div>

              <div style="flex:2;
                border-radius: 0 0.0781rem 0.0781rem 0;
                background-color:#ffffff;
                padding: .0781rem;
                border: 0.0156rem solid #dadee3;
                border-left: 0;
                padding-left:0.1563rem;overflow:hidden">
                ${info.sitename}
                </div>
            </div>
            `
        );
        content.push(
          `
           <div style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size:.1875rem;
              margin:  .125rem 0;
              color:black"
            >
              <div style="
              border: 0.0156rem solid #dadee3;
              padding: .0781rem 0.1563rem;
              border-radius: 0.0781rem 0 0 0.0781rem;
              background-color:#e9ecef">设备状态</div>

              <div style="flex:2;
                border-radius: 0 0.0781rem 0.0781rem 0;
                background-color:#ffffff;
                padding: .0781rem;
                border: 0.0156rem solid #dadee3;
                border-left: 0;
                padding-left:0.1563rem;overflow:hidden">
                ${
                  info.online === 0
                    ? "离线"
                    : info.online === 1
                    ? "在线"
                    : "本地测试"
                }
                </div>
            </div>
            `
        );
        content.push(
          `
            <div style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size:.1875rem;
              margin:  .125rem 0;
              color:black"
            >
              <div style="
              border: 0.0156rem solid #dadee3;
              padding: .0781rem 0.1563rem;
              border-radius: 0.0781rem 0 0 0.0781rem;
              background-color:#e9ecef">设备编号</div>

              <div style="flex:2;
                border-radius: 0 0.0781rem 0.0781rem 0;
                background-color:#ffffff;
                padding: .0781rem;
                border: 0.0156rem solid #dadee3;
                border-left: 0;
                padding-left:0.1563rem;">${info.sn}</div>
            </div>
            `
        );
        content.push(
          `
           <div style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size:.1875rem;
              margin:  .125rem 0;
              color:black"
            >
              <div style="
              border: 0.0156rem solid #dadee3;
              padding: .0781rem 0.1563rem;
              border-radius: 0.0781rem 0 0 0.0781rem;
              background-color:#e9ecef">累计水量</div>

              <div style="flex:2;
                border-radius: 0 0.0781rem 0.0781rem 0;
                background-color:#ffffff;
                padding: .0781rem;
                border: 0.0156rem solid #dadee3;
                border-left: 0;
                padding-left:0.1563rem;overflow:hidden">
                ${info.sumWater}m³
                </div>
            </div>
            `
        );
        content.push(
          `
           <div style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size:.1875rem;
              margin:  .125rem 0;
              color:black"
            >
              <div style="
              border: 0.0156rem solid #dadee3;
              padding: .0781rem 0.1563rem;
              border-radius: 0.0781rem 0 0 0.0781rem;
              background-color:#e9ecef">累计电量</div>

              <div style="flex:2;
                border-radius: 0 0.0781rem 0.0781rem 0;
                background-color:#ffffff;
                padding: .0781rem;
                border: 0.0156rem solid #dadee3;
                border-left: 0;
                padding-left:0.1563rem;overflow:hidden">
                ${info.sumEle}kwh
                </div>
            </div>
            `
        );
        content.push(
          `
            <div style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size:.1875rem;
              margin:  .125rem 0;
              color:black"
            >
              <div style="
              border: 0.0156rem solid #dadee3;
              padding: .0781rem 0.1563rem;
              border-radius: 0.0781rem 0 0 0.0781rem;
              background-color:#e9ecef">经度纬度</div>

              <div style="flex:2;
                border-radius: 0 0.0781rem 0.0781rem 0;
                background-color:#ffffff;
                padding: .0781rem;
                border: 0.0156rem solid #dadee3;
                border-left: 0;
                padding-left:0.1563rem;">
                ${info.applng.toFixed(6)},${info.applat.toFixed(6)}</div>
            </div>
            `
        );
      }
      // 分割线
      content.push("<hr/>");

      content.push("</div>");

      // 实现自定义窗体内容，返回拼接后的字符串
      function createInfoWindow(title, content, Btn_title) {
        // 创建悬浮窗主体
        const mainBody = document.createElement("div");
        mainBody.style.width = "460px";
        mainBody.style.backgroundColor = "#FFF";
        mainBody.style.padding = ".1563rem";
        mainBody.style.boxShadow = "0.313rem 0.313rem 0.625rem rgba(0,0,0,0.5)";
        mainBody.style.borderRadius = "0.0781rem";

        // 创建悬浮窗标题
        const mainTitle = document.createElement("div");
        mainTitle.style.display = "flex";
        mainTitle.style.justifyContent = "space-between";
        mainTitle.style.alignItems = "center";
        mainTitle.style.fontSize = ".1875rem";
        mainTitle.style.color = "#b0b0b0";
        mainBody.appendChild(mainTitle);

        // 创建标题文字
        const mainHead = document.createElement("div");
        mainHead.innerHTML = title;
        mainHead.style.fontWeight = "700";
        mainTitle.appendChild(mainHead);

        // 创建标题关闭按钮
        const mainClose = document.createElement("div");
        mainClose.innerHTML = "X";
        mainClose.style.cursor = "pointer";
        mainTitle.appendChild(mainClose);

        // 点击关闭按钮时触发
        mainClose.onclick = closeFn;

        // 悬浮窗内容添加
        const mainContent = document.createElement("div");
        mainContent.innerHTML = content;
        mainBody.appendChild(mainContent);

        // 0代表显示悬浮窗下方按钮
        if (id === 0) {
          //按钮
          const btns = document.createElement("div");
          btns.style.display = "flex";
          btns.style.fontSize = ".1875rem";
          btns.style.marginTop = ".125rem";
          btns.style.color = "#0ccae7";

          // 设备详情按钮
          const deviceBtn = document.createElement("div");
          deviceBtn.innerHTML = Btn_title;
          deviceBtn.style.padding = "0.0781rem .125rem";
          deviceBtn.style.border = ".0234rem solid #0ccae7";
          deviceBtn.style.borderRadius = "0.2344rem";
          deviceBtn.style.backgroundColor = "#FFF";
          deviceBtn.style.fontweight = "700";
          deviceBtn.style.cursor = "pointer";
          deviceBtn.style.marginRight = ".0781rem";
          btns.appendChild(deviceBtn);

          // 设备详情按钮的点击事件
          deviceBtn.onclick = deviceFn;
          // 将按钮存入到主体窗口中
          mainBody.appendChild(btns);
        }

        return mainBody;
      }

      // 创建 infoWindow 实例
      const title = "设备信息";
      var infoWindow = new AMap.InfoWindow({
        isCustom: true, //使用自定义窗体
        content: createInfoWindow(
          title,
          content.join(""),
          this.props_promptTitle
        ), //传入 dom 对象，或者 html 字符串
        offset: new AMap.Pixel(16, -50),
      });

      // 打开悬浮框
      infoWindow.open(that.map, marker.getPosition());

      // 关闭悬浮框
      function closeFn() {
        // 关闭信息窗体
        infoWindow.close();
      }

      // 修改设备信息
      function deviceFn() {
        if (that.props_promptTitle === "查看设备详细信息") {
          // 传递数据
          that.$store.commit("weatherData/getWeatherData", info);
          // 判断打开的是哪个组件
          that.$store.commit("popupState/option", 1);
          // 打开弹窗
          that.$store.commit("popupState/open", true);
          // 关闭高德地图信息窗体
          infoWindow.close();
        } else {
          const routes = {
            met: { path: "/weather" }, //气象设备
            soil: { path: "/soil" }, //墒情设备
            wormFlagship: { path: "/insect" }, //虫情设备
            worm: { path: "/insect" }, //虫情设备
            controller: { path: "/controller" }, //控制器
          };
          // 页面跳转
          that.$router.push({
            path: routes[info.deviceType].path,
          });
          // 关闭高德地图信息窗体
          infoWindow.close();
        }
      }

      function deviceTypes(value) {
        const deviceTypes = {
          met: "气象设备",
          soil: "墒情设备",
          wormFlagship: "虫情设备",
          worm: "虫情设备",
          controller: "控制器",
        };
        return deviceTypes[value] || "未知设备类型";
      }
    },

    // 设备图标icon转换
    convert(name) {
      const deviceTypes = {
        met: "weather_drop",
        soil: "soil_drop",
        wormFlagship: "insect_drop",
        worm: "insect_drop",
        controller: "controller_drop",
      };
      return deviceTypes[name] || "controller_drop";
    },
  },

  beforeDestroy() {
    this.map?.destroy();
    this.geoCoordinatesList = [];
  },

  watch: {
    props_geoCoordinatesList: {
      handler(newValue) {
        this.geoCoordinatesList = newValue;
        this.$nextTick(() => {
          this.initAMap();
        });
      },
      deep: true,
      immediate: true,
    },

    basic_data: {
      handler(newValue) {
        this.baiscDataList = newValue;
        this.$nextTick(() => {
          this.initAMap(1);
        });
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/utils/css/global.scss";

.module {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: vh(450);
  color: #fff;

  .map {
    width: 100%;
    height: 100%;

    .map-container {
      width: 100%;
      height: 100%;

      ::v-deep .amap-copyright {
        opacity: 0 !important;
      }

      ::v-deep .amap-logo {
        opacity: 0 !important;
      }
    }

    .searchBox {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0.3125rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: 9999;
      width: 7.8125rem;
      height: 0.9375rem;
      padding: 0 0.0781rem;

      .title {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.5625rem;
        height: 0.7813rem;
        padding: 0 0.0781rem;
        font-size: 0.1875rem;
        font-weight: 600;
        background: linear-gradient(to bottom, #38cfbe, #076d7b);
        border-radius: 0.1563rem;
        letter-spacing: 0.0313rem;
        margin-right: 0.0781rem;
        cursor: pointer;

        &:last-child {
          margin: 0;
        }
      }

      .titleselect {
        border: 0.0313rem solid #00ffff;
      }
    }
  }
}
</style>
