/**
 *
 * 混入 Mixin.js文件
 * 已在main.js文件中全局挂载
 * 此文件只存放公共的js逻辑,data()中的数据不可存放
 *
 */

// 引入工具函数
import { messagePromptAPI } from "@/utils/tool.js";

export default {
    data() {
        return {
            index: 0, //索引,只显示1条数据信息
            is_Camera: true, //默认展示
            myChart: null, //Echarts实例
            timer_mixin: null, //定时器实例
        }
    },

    methods: {
        /**
         * 通过数据量判断表格是否自动滚动
         */
        scrollControl() {
            // 获取vue-seamless-scroll组件的Dom元素
            const scrollListDom = document.querySelector(".seamless-warp");
            if (scrollListDom) {
                // 获取表格最后一行的Dom元素
                const scrollListDomLast = scrollListDom.querySelectorAll(
                    ".table-deviceDetails-content"
                )[
                    scrollListDom.querySelectorAll(".table-deviceDetails-content")
                    .length - 1
                ];

                // 给vue-seamless-scroll组件添加实例属性
                this.optionHover = {
                    limitMoveNum: 20,
                    step: 0.5,
                    // autoPlay为false则不自动滚动，反之则开发自动滚动
                    autoPlay: scrollListDomLast.offsetTop > scrollListDom.clientHeight,
                };

                if (this.optionHover.autoPlay) {
                    /***
                     * vue-samless-scroll插件的data属性有值会自动复制一份并进行渲染
                     * 所以当表格滚动时复制,反之不需要复制
                     */
                    this.copyData = this.dataList;
                }
            }
        },

        /**
         * 定时循环数据
         */
        Timed_cycleDdata(newValue) {
            let that = this
            if (newValue.length == 1) return;
            let j = 0
            that.timer_mixin = setInterval(() => {
                this.index++;
                if (this.index < newValue.length) {
                    this.init(newValue);
                    return;
                }
                this.index = 0;
                this.init(newValue);
            }, 3000)
        },

        /**
         * 清除定时器
         */
        clear_TimerMixin() {
            const result = clearInterval(this.timer_mixin)
            result || console.log(`成功`);
        },

        /**
         * 四情中的历史记录查询js逻辑
         *      历史查询 + 查询结果判断 + 分页处理
         *      1.气象2.孢子3.墒情4.虫情
         */

        // 历史查询
        querySearch() {
            this.loadingState = true;
            // 点击查询按钮---pages为1
            this.historyForm.pages = 1;
            this.getHistoryList();
        },

        // 查询结果判断
        resultEvaluation(data) {
            if (data.rows.length == 0) {
                this.searchList = [];
                this.loadingState = false;
                messagePromptAPI({ code: 20, message: "暂无数据", data: "" });
            } else {
                this.loadingState = false;
                this.searchList = data.rows;
                this.total = data.total;
                this.totalPages = data.totalPages;
            }
        },

        // 分页处理
        querySearchPrev(value) {
            this.historyForm.pages = value;
            this.getHistoryList();
        },
    },
};